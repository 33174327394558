import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Layout from "src/components/layout"
import SubpageBanner from "src/components/SubpageBanner"
import SEO from "src/components/seo"
import SimpleText from "src/components/simple-text"
import Breadcrumbs from "src/components/Breadcrumbs"
import Sidebar from "src/components/Sidebar"

const breadcrumbs = [
  { url: "a-better-way", handle: "A Better Way" },
  {
    url: "a-better-way/innovative-design",
    handle: "Innovative Design",
  },
]

const InnovativeDesign = () => (
  <StaticQuery
    query={graphql`
      query {
        one: file(relativePath: { eq: "excellerator-3.jpg" }) {
          childImageSharp {
            fixed(quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        background: file(relativePath: { eq: "discarded-cells-banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const one = data.one.childImageSharp.fixed
      const background = data.background.childImageSharp.fluid

      return (
        <Layout>
          <SEO title="Innovative Design - ExCellerator" />
          <SubpageBanner image={background} heading="Innovative Design" />
          <div className="wrapper wrapper--narrow p-bottom p-sides">
            <Breadcrumbs items={breadcrumbs} />
            <div className="split-contain wrapper">
              <div className="split-contain__sidebar">
                <Sidebar image={one} />
              </div>
              <div className="split-contain__contain">
                <SimpleText copy="Immediately following the introduction of the ThinPrep Pap Test system, clients began to experience and complain about results of specimen inadequacy from samples they contended were quality collections. We experienced clients actually switching back to the conventional method out of frustration. We knew that the new system was better overall, so we set out to find out what was causing the issue." />
                <SimpleText copy="Based on our initial assessment and also broader industry sentiment, we quickly identified sample transfer from the cytobrush as the main issue. It was clear that the sample would not easily dislodge from the bristles of the brush. Basic testing of the cytobrush following standard use confirmed that a significant amount of sample was being discarded along with the device rather than being transferred into the vial. Larger follow-up studies indicated significant and consistent transfer issues." />
                <SimpleText copy="It didn’t take long to find a simple and effective way to help clinicians get the valuable sample they had worked hard to collect. We found that simply adding a hole in the spatula that would aid the user in teasing the sample out of the bristles was the right solution. Following development, studies were conducted that confirmed not only how much sample was normally left behind, but more importantly, that we now had a way to capture it!" />
                <SimpleText copy="Validation data has been tracked and evaluated across over 1 million samples. Analysis shows consistent and significant improvement in adequacy, as well as significant gain in well- preserved and valuable cells." />
              </div>
            </div>
          </div>
        </Layout>
      )
    }}
  />
)

export default InnovativeDesign
